import {createApp} from "vue";
import App from "./App.vue";
import router from "./router";
import "normalize.css";
import "./styles/index.less";
import {Row, Col,Icon,Card,Button} from "view-ui-plus";
const app = createApp(App);
app.component("Row", Row);
app.component("Col", Col);
app.component("Icon", Icon);
app.component("Card", Card);
app.component("Button", Button);
app.use(router).mount("#app");
