import {createRouter, createWebHistory} from "vue-router";
const routes = [
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/Home.vue"),
  },
  {
    path: "/business",
    name: "business",
    component: () => import("@/views/Business.vue"),
  },
  {
    path: "/company",
    name: "company",
    component: () => import("@/views/Company.vue"),
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("@/views/Profile.vue"),
  },
  {
    path: "/message",
    name: "message",
    component: () => import("@/views/Message.vue"),
  },
  {
    path: "/privacypolicy",
    name: "privacypolicy",
    component: () => import("@/views/Privacypolicy.vue"),
  },
  {
    path: "/security",
    name: "security",
    component: () => import("@/views/Security.vue"),
  },
  {
    path: "/sitemap",
    name: "sitemap",
    component: () => import("@/views/Sitemap.vue"),
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/home",
  },
];

const router = createRouter({
  routes,
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to) {
    if (to.hash === "#footer") {
      const targetElement = document.getElementById("footer");
      if (targetElement) {
        setTimeout(function () {
          targetElement.scrollIntoView({behavior: "smooth"});
        }, 300);
        return;
      }
    }
    return {top: 0};
  },
});

export default router;
